import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

function Navigation(props) {
  const { location } = props
  const [isSticky, setIsSticky] = useState(location.pathname !== "/")
  const [showNavbarCollapse, setShowNavbarCollapse] = useState(false)
  const navbarClasses = [
    "navbar",
    "navbar-expand-lg",
    "fixed-top",
    "addscrollspy",
  ]
  const navbarCollapseClasses = [
    "collapse",
    "navbar-collapse",
    "ml-auto",
    "mr-auto",
  ]
  const burgerClasses = ["burger"]

  useEffect(() => {
    // following only on home
    if (location.pathname !== "/") {
      return
    }

    // Navbar sticky
    addSticky()
    document.onscroll = function() {
      addSticky()
    }
    function addSticky() {
      const navbar = document.getElementById("navbar"),
        sticky = navbar.offsetTop
      if (window.pageYOffset !== sticky) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    }
  }, [])

  const handleNavbarToggle = () => {
    setShowNavbarCollapse(!showNavbarCollapse)
    document.querySelector("body").classList.toggle("hide-overflow")
  }

  if (showNavbarCollapse) {
    burgerClasses.push("active")
    navbarCollapseClasses.push("show")
  }
  if (isSticky) navbarClasses.push("sticky")

  return (
    <nav className={navbarClasses.join(" ")} id="navbar">
      <Link className="navbar-brand font-weight-bolder mr-xl-3" to="/">
        <img
          className="navbar-logo navbar-logo--inverted"
          src="/assets/img/logo-inverted.svg"
          alt="Marco Mazzocchi"
        />
        <img
          className="navbar-logo navbar-logo--default"
          src="/assets/img/logo-default.svg"
          alt="Marco Mazzocchi"
        />
      </Link>
      <button
        className="navbar-light navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbars-default"
        aria-controls="navbars-default"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={handleNavbarToggle}
      >
        <span className="navbar-toggler-icon">
          <span className={burgerClasses.join(" ")}>
            <span className="burger__line" />
            <span className="burger__line" />
            <span className="burger__line" />
          </span>
        </span>
      </button>
      <div className={navbarCollapseClasses.join(" ")} id="navbars-default">
        <ul
          className="navbar-nav ml-auto mr-auto"
          // if user click anywhere on this element close burger
          onClick={() => {
            if (showNavbarCollapse) {
              handleNavbarToggle()
            }
          }}
        >
          <li className="nav-item">
            <Link className="nav-link active" to="/#index">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/#about">
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/#blog-featured">
              Blog
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/#contact">
              Contact
            </Link>
          </li>
        </ul>
        <span className="navbar__slideline" id="slideline" />
      </div>
    </nav>
  )
}

export default Navigation
