import React, {useState, useEffect} from "react"

export default function CookieBanner() {

    const [policyAccepted, setPolicyAccepted] = useState(false);
    const localStorageCookieKey = 'cookie-policy-accepted';

    const closeBanner = event => {
        event.preventDefault();
        localStorage.setItem(localStorageCookieKey, true);
        setPolicyAccepted(true);
    };

    useEffect(() => {
        const localStorageCookie = localStorage.getItem(localStorageCookieKey) === 'true';
        setPolicyAccepted(localStorageCookie);
    }, []);

    if(policyAccepted) return null;

    return (
        <div className='cookie-banner'>
            Questo sito registra dati utenti. Continuando a navigare o chiudendo questo banner acconsenti alla <a href="/privacy-policy" title="Privacy Policy">Privacy Policy</a>, inclusa la cookie policy.
            <a onClick={closeBanner} href="#" title="accetto le condizioni" className="accept">Acconsento</a> 
            <span onClick={closeBanner} className="float-right"><i className="lni lni-close"></i></span>
        </div>
    );
}