import React from "react"
import { Link } from "gatsby"
import Preloader from "./preloader"
import Navigation from "./layout/Navigation"
import Footer from "./layout/Footer"
import CookieBanner from "./layout/cookie-banner"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    return (
      <div>
        {/* <Preloader /> */}
        <CookieBanner />
        <Navigation location={location} />
        <main>{children}</main>
        <Footer />
      </div>
    )
  }
}

export default Layout
