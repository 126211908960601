import React from "react"

function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-12 col-md-3 text-left">
            <p>© {new Date().getFullYear()} Copyright Marco Mazzocchi</p>
          </div>
          <div className="col-12 col-md-9 text-md-right">
            <ul>
            <li>
                <a href="/privacy-policy" data-flip="Privacy Policy">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/credits" data-flip="Credits">
                  credits
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/marco-mazzocchi-pc/"
                  target="_blank"
                  data-flip="LinkedIn"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  href="https://medium.com/@marco.mazzocchi.pc"
                  target="_blank"
                  data-flip="Medium"
                >
                  Medium
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/marco-mazzocchi/"
                  target="_blank"
                  data-flip="Github"
                >
                  Github
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
